<template>
  <a-row
    type="flex"
    justify="center"
  >
    <a-col span="6">
      <img alt="alipay" src="~@/assets/images/alipay_logo.svg">
    </a-col>

    <a-col span="12" class="notice">
      请在浏览器新标签页完成支付！
    </a-col>
  </a-row>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.notice {
  margin-top: 5px;
  font-size: 25px;
}
</style>
