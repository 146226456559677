<template>
  <div class="box">
    <a-row
      type="flex"
    >
      <a-col>
        <a-button type="primary" @click="$router.go(-1)">
          返回
        </a-button>
      </a-col>
    </a-row>

    <a-row class="info">
      <a-col>
        <a-descriptions :column="2">
          <a-descriptions-item label="订单类型">
            {{ data.subject }}
          </a-descriptions-item>

          <a-descriptions-item label="订单编号">
            {{ data.out_trade_no }}
          </a-descriptions-item>

          <a-descriptions-item label="支付平台">
            {{ data.third_party_type }}
          </a-descriptions-item>

          <a-descriptions-item label="应付金额">
            ￥{{ data.amount | formatCurrency }}
          </a-descriptions-item>

          <a-descriptions-item label="订单状态">
            {{ data.status }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>

    <a-divider v-if="isUnpaid" />

    <wechat-pay-qr-code
      v-if="isShowWechatPayQrCode"
      :qr-code="qr_code"
    />

    <alipay-notice v-if="isAlipay" />
  </div>
</template>
<script>
import { findPayTrade, findPayTradeSkipExtendAuth } from '@/api/pay_trade'
import WechatPayQrCode from '@/views/pay_trade/WechatPayQrCode'
import AlipayNotice from '@/views/pay_trade/AlipayNotice'

export default {
  components: {
    AlipayNotice,
    WechatPayQrCode
  },
  data() {
    return {
      data: {},
      qr_code: '',
      timer: ''
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    },

    isUnpaid() {
      return this.data.status_slug === 'unpaid'
    },

    isAlipay() {
      return this.data.third_party_type_slug === 'alipay'
    },

    // 是否显示微信支付二维码
    isShowWechatPayQrCode() {
      return this.data.third_party_type_slug === 'wechat_pay' &&
        this.data.status_slug === 'unpaid' &&
        this.qr_code.length > 0
    }
  },
  created() {
    this.fetchData()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    fetchData() {
      findPayTrade(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.toPay()
        }
      })
    },

    successfulModal() {
      const vm = this
      this.$confirm({
        title: '您已支付成功！',
        content: (
          <div>
            <p>点击返回，返回充值页面</p>
            <p>点击查看余额，返回余额页面</p>
          </div>
        ),
        okText: '查看余额',
        cancelText: '返回',
        icon: 'check-circle',
        onOk() {
          vm.$router.push({ name: 'balance_statistics' })
        },
        onCancel() {
          vm.$router.push({ name: 'balance_recharge' })
        }
      })
    },

    checkPayStatus() {
      findPayTradeSkipExtendAuth(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data

          if (this.data.status_slug === 'successful') {
            clearInterval(this.timer)
            this.successfulModal()
          }
        }
      })
    },

    // 展示支付信息(二维码或跳转到支付页面)
    toPay() {
      // 仅未支付的展示
      if (this.data.status_slug !== 'unpaid') {
        return
      }

      switch (this.data.third_party_type_slug) {
        case 'wechat_pay':
          this.qr_code = this.data.pay_url
          break
        case 'alipay':
          window.open(this.data.pay_url, '_blank')
          break
      }

      // 轮询查询支付状态
      this.timer = setInterval(this.checkPayStatus, 2000)
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  background-color: #ececec;
  padding: 20px 20px 0 20px;
  margin-top: 20px;
}
</style>
