<template>
  <a-row
    type="flex"
    justify="center"
  >
    <a-col span="6">
      <img alt="wechat_pay" src="~@/assets/images/wechat_pay_logo.svg">
    </a-col>

    <a-col span="12">
      <qrcode-vue
        v-if="qrCode.length > 0"
        :value="qrCode"
        :size="200"
        level="H"
      />
      <img
        alt="wechat_pay"
        class="wechat-scan-desc"
        src="~@/assets/images/wechat_scan_desc.png"
      >
    </a-col>
  </a-row>
</template>

<script>
export default {
  components: {
    QrcodeVue: () => import('qrcode.vue')
  },
  props: {
    qrCode: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.wechat-scan-desc {
  margin-top: 10px;
  width: 200px;
}
</style>
